import axios from '../axios';

export const all = (params = {}) => {
  return axios.get('/inspirations', { params });
};

export const create = (data) => {
  return axios.post('/inspirations', data);
};

export const show = (slug, lang) => {
  return axios.get(`/inspirations/${slug}`, {
    params: {
      lang,
    },
  });
};

export const update = (slug, data) => {
  return axios.post(`/inspirations/${slug}`, data);
};

export const destroy = (slug) => {
  return axios.delete(`/inspirations/${slug}`);
};

export const getSite = (lang) => {
  return axios.get(`/inspirations-page`, {
    params: {
      lang,
    },
  });
};

export const updateSite = (data) => {
  return axios.post(`/inspirations-page`, data);
};
